@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.main-charts__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    max-width: 100%;
    min-height: 40rem;
}

.main-charts__btns {
    display: flex;
    column-gap: 1.6rem;
    margin-bottom: .8rem;
}

.main-charts__upload-label {
    cursor: pointer;
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
        background-color: darken($primary-color, 10%);
    }
}

.main-charts__chart {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    &._line {
        flex: 0 0 calc(3*(100% - 1rem)/4);
        max-width: calc(3*(100% - 1rem)/4);

        @media (max-width: $desktop-lg) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &._bar {
        flex: 0 0 calc((100% - 3*3rem)/4);
        max-width: calc((100% - 3*3rem)/4);

        @media (max-width: $desktop-lg) {
            flex-basis: calc((100% - 3rem)/2);
            max-width: calc((100% - 3rem)/2);
        }
    }
}

.main-charts__chart-wrap {
    flex-grow: 1;
}

.main-charts__chart-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;

    .btn {
        margin-right: -1rem;
    }
}

.main-charts__chart-title {
    color: $secondary-text-color;
    font-size: 2rem;
    font-weight: 600;
    line-height: 140%;
}

.main-charts__chart-controls {
    @extend %flex-center-y;
    justify-content: space-between;
    column-gap: 1.6rem;
    margin-bottom: 2.4rem;
}

.main-charts__chart-checkbox-list {
    @extend %flex-center-y;
    column-gap: 1.6rem;
}

.main-charts__chart-bar-info {
    margin-top: 2rem;
}

.bar-info__item {
    @extend %flex-center-y;
    justify-content: space-between;
    padding-left: .6rem;

    + .bar-info__item {
        margin-top: 1.2rem;
    }

    span {
        font-size: 1.4rem;
        line-height: 140%;
    }
}

.bar-info__item-left-part {
    @extend %flex-center-y;
    column-gap: .8rem;
}

.bar-info__circle {
    @include size(.6rem);
    position: relative;
    margin-right: .4rem;
    border-radius: 50%;

    &::before {
        @extend %center;
        @include size(1.2rem);
        content: "";
        display: block;
        background-color: inherit;
        border-radius: 50%;
        opacity: .3;
    }
}

.bar-info__icon {
    margin-right: -.4rem;
    font-size: 1.6rem;
}
