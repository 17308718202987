@import '../../styles/variables.scss';

.infographics-list {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;

    > .infographics-item {
        flex: 0 0 calc((100% - 3*3rem)/4);
        max-width: calc((100% - 3*3rem)/4);

        @media (max-width: $desktop-lg) {
            flex-basis: calc((100% - 3rem)/2);
            max-width: calc((100% - 3rem)/2);
        }
    }
}