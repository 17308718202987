@font-face {
    font-family: 'Archivo';
    src: url('../resources/fonts/Archivo_Condensed-SemiBold.woff') format('woff'),
         url('../resources/fonts/Archivo_Condensed-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archivo';
    src: url('../resources/fonts/Archivo_Condensed-Regular.woff') format('woff'),
         url('../resources/fonts/Archivo_Condensed-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}