@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.header {
    @include fixed($top: 0, $right: 0, $left: 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    padding: 2rem 3.2rem 2rem calc(3.2rem + $menu-width);
    background-color: $white;
    border-bottom: thin solid $blue-300;
    z-index: 10;

    .avatar {
        margin-left: .8rem;
    }
}

.header__left-part,
.header__right-part {
    @extend %flex-center-y;
    column-gap: 2rem; /* Adjusted to match the margin between elements */
}

.header__right-part {
    column-gap: 0;
}

.search-control {
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    width: 320px;
    border: 1px solid $blue-300;
    border-radius: $border-radius;
    height: 36px; /* Match height of year picker */
    
    .btn-icon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
    
    input {
        background-color: transparent !important;
        border: none !important; /* Remove default border */
        padding: 0.8rem 1.2rem !important;
        height: 34px;
        min-width: auto !important;
        width: calc(100% - 40px) !important;
        font-size: 1.4rem !important;
        color: $blue-800 !important;
        
        &::placeholder {
            color: $blue-gray-500 !important;
        }
        
        &:focus,
        &:focus-visible {
            outline: none;
            box-shadow: none;
            background-color: transparent !important;
        }
    }
}

.header__year-picker {
    /* Let the inline styles handle most of the styling */
    /* We just add the border that matches the search field */
    display: inline-flex;
    border: 1px solid $blue-300;
    border-radius: $border-radius;
    background-color: transparent;
    
    .MuiInputBase-root {
        background-color: transparent;
        
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
}

.MuiFilledInput-root {
    background-color: transparent;
    
    &:hover, &.Mui-focused {
        background-color: transparent;
    }
}

/* Material UI global overrides */
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $blue-500;
}