@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.avatar {
    @extend %flex-center;
    @include size(6.2rem);
    background-color: $blue-gray-100;
    border: thin solid $blue-300;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer; /* Добавлено для изменения курсора */

    img {
        @include size(100%);
        object-fit: cover;
    }

    &._large {
        @include size(8rem);

        .avatar__text {
            font-size: 2.4rem;
        }
    }

    &._sm {
        @include size(3rem);

        .avatar__text {
            font-size: 1.2rem;
        }
    }
}

.avatar__text {
    color: $blue-500;
    font-size: 1.8rem;
    line-height: 100%;
}
