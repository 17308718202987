@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.comments {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 1.5rem));
    gap: 3rem;

    @media(max-width: $desktop-lg) {
        grid-template-columns: 1fr;
    }
}

.comments__table {
    background-color: $white;
    box-shadow: $box-shadow;
}

.comments__table-header {
    @extend %flex-center-y;
    justify-content: space-between;
    padding: 2rem;
}

.comments__table-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
    padding: 0 2rem;
    border-top: thin solid $blue-gray-400;

    &:nth-child(2n) {
        background-color: $blue-gray-50;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.comments__table-cell {
    padding: 1.2rem 0;
    font-size: 1.6rem;
    line-height: 150%;
    color: $blue-gray-600;

    &:first-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.comments__table-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 140%;
    color: $blue-gray-600;
}

.comments__table-control {
    display: flex;
}

.comments__table-btn {
    margin-left: .4rem;
    margin-right: -1.2rem;
}