%center {
    transform: translate(-50%, -50%);
    @include absolute($top: 50%, $left: 50%);
}

%centerY {
    transform: translateY(-50%);
    @include absolute($top: 50%);
}

%centerX {
    transform: translateX(-50%);
    @include absolute($left: 50%);
}

%flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%flex-center-y {
    display: flex;
    align-items: center;
}

%flex-center-x {
    display: flex;
    justify-content: center;
}