@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.tasks-item {
    display: grid;
    grid-template-columns: 2.8rem 16% 13% auto repeat(4, 14rem);
    align-items: center;
    position: relative;
    padding: .6rem .6rem .6rem 3.2rem;
    background-color: $white;
    border-bottom: thin solid $blue-50;
    cursor: pointer;
    transition: background-color .3s ease-out;

    &:hover {
        background-color: $blue-50;
    }

    &._not-readed {
        &::before {
            @include absolute($left: .1rem, $top: 0);
            @include size(.4rem, 100%);
            content: "";
            display: block;
            background-color: $blue-500;
        }

        .tasks-item__item._user-name {
            font-weight: 600;
        }

        .tasks-item__item-task-name {
            color: $blue-500;
            font-weight: 600;
        }
    }

    &._completed {
        grid-template-columns: 2.8rem 19% 15% auto  14rem 16rem;
    }

    &._new {
        grid-template-columns: 2.8rem 18% 15% auto 14rem;
    }
}

.tasks-item__avatar,
.tasks-item__avatar-default {
    @include size(2.8rem);
    border-radius: 50%;
    overflow: hidden;
}

.tasks-item__avatar {
    background-color: $blue-gray-200;

    img {
        @include size(100%);
        object-fit: cover;
    }
}

.tasks-item__avatar-default {
    @extend %flex-center;
    background-color: $pink-600;

    span {
        color: $white;
        font-size: 1rem;
        line-height: 100%;
    }
}

.tasks-item__item {
    padding: .1rem 1.6rem;
    font-size: 1.4rem;
    line-height: 114%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &._task-title {
        color: $secondary-text-color;
    }
}

.tasks-item__item-task-name {
    margin-right: 1.2rem;
    font-size: 1.4rem;
    line-height: 114%;
}