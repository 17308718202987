@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

//Position

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin absolute($args...) {
  @include position(absolute, $args...);
}

@mixin relative($args...) {
  @include position(relative, $args...);
}

@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@mixin sticky($args...) {
  @include position(sticky, $args...);
}

@mixin button-style($color: false, $color-hover: false, $background-color: false, $background-color-hover: false, $background-image: false, $background-image-hover: false, $border-color: false, $border-color-hover: false) {
	@if $background-image {
		background-image: $background-image;
    }

	@if $background-color {
		background-color: $background-color;
  }

	@if $border-color {
		border-color: $border-color;
    }
    
  @if $color {
    color: $color;

    .icon {
      color: $color;
      transition: color .3s ease-out;
    }
  }  

	&:focus-visible,
	&:hover {
		@if $background-color-hover {
      background-color: $background-color-hover;
    }

		@if $border-color-hover {
			border-color: $border-color-hover;
    }
        
    @if $color-hover {
      color: $color-hover;

      .icon {
        color: $color-hover;
      }
    }
  }            
}