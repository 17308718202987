$green-200:                   #BBF7D0;
$green-400:                   #4ADE80;
$green-500:                   #22C55E;  
$green-800:                   #0F7B0F; 
$blue-50:                     #EFF6FF;
$blue-100:                    #D3E3F8;
$blue-300:                    #93C5FD;
$blue-500:                    #3B82F6;
$blue-600:                    #2563EB;
$blue-700:                    #1D4ED8;
$blue-800:                    #1E40AF;
$blue-gray-50:                #F8FAFC;
$blue-gray-100:               #F3F4F6;
$blue-gray-200:               #D9D9D9;
$blue-gray-400:               #94A3B8;
$blue-gray-500:               #64748B;
$blue-gray-600:               #475569;
$blue-gray-700:               #265388;
$blue-gray-800:               #1E293B;
$pink-400:                    #F472B6;
$pink-600:                    #D02C89;
$violet-700:                  #7D2393;
$red-100:                     #FEE2E2;
$red-400:                     #F87171;
$red-700:                     #C42B1C;
$orange-100:                  #FFEDD5;
$orange-300:                  #E09B33;
$orange-400:                  #FB923C;
$white:                       #FFFFFF;

$main-text-color:               $blue-gray-800;
$secondary-text-color:          $blue-gray-600;
$main-bg-color:                 $blue-gray-100;
$main-selection-color:          $blue-300;
$scrollbar-track-bg-color:      $white;
$scrollbar-thumb-bg-color:      $blue-50;
$scrollbar-thumb-shadow-color:  $blue-gray-700;

$primary-color:                 $blue-500;
$secondary-color:               $blue-500;

$box-shadow:                    0 0 1px 0 rgba(9, 30, 66, 0.31), 0 3px 8px 0 rgba(9, 30, 66, 0.16);

$font-family:                   "Archivo", "Helvetica Neue", Arial, sans-serif;

$mobile-sm:                     480px;
$mobile:                        768px;
$tablet:                        1024px;
$desktop:                       1260px;
$desktop-lg:                    1440px;

$header-height:                 10rem;
$menu-width:                    26.4rem;

$control-size:                  5.6rem;
$control-size-sm:               4rem;
$control-size-xs:               3.4rem;

$border-radius:                 .4rem;