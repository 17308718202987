@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/extends.scss';

input[type='text'],
textarea,
select {
    min-width: 24rem;
    width: 100%;
    padding: 1.6rem 2.4rem;
    background-color: transparent; /* Changed from $blue-50 to transparent */
    border: thin solid $blue-300; /* Added a light border */
    border-radius: $border-radius;
    font-size: 1.6rem;
    line-height: 150%;

    &::-webkit-input-placeholder,
    &::placeholder {
        color: $blue-gray-500;
    }

    &:focus,
    &:focus-visible {
        outline: none;
        box-shadow: none;
        /* Remove any browser default focus styles */
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
}

input[type='text'],
select {
    height: $control-size;
}

input.input-sm,
.input-sm select {
    height: $control-size-sm;
    padding: 0 2rem;
    font-size: 1.4rem;
}

textarea {
    resize: none;
}

textarea.input-sm {
    padding: 1.6rem 2rem;
    font-size: 1.4rem;
}
select {
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.form-control {
    label {
        display: block;
        margin-bottom: .4rem;
        font-size: 1.4rem;
    }

    + .form-control {
        margin-top: 1.6rem;
    }

    &._select-control {
        position: relative;

        &::after {
            @include absolute($right: 1.2rem, $bottom: 1.6rem);
            content: "\e90b";
            font-family: "icomoon";
            font-size: 2rem;
            color: $secondary-text-color;
            pointer-events: none;
        }

        &.input-sm::after {
            bottom: 1rem;
            font-size: 1.6rem;
        }
    }

    input[type='checkbox'] {
        display: none;
    
        + label {
            position: relative;
            margin-bottom: 0;
            padding-left: 3.6rem;
            cursor: pointer;
    
            &::before {
                @include absolute($left: 0, $top: .7rem);
                @include size(2.2rem);
                @extend %centerY;
                content: "";
                display: block;
                border: thin solid $primary-color;
                border-radius: $border-radius;
                transition: background-color .2s ease-out;
            }
    
            &::after {
                @include absolute($left: .4rem, $top: -.2rem);
                content: "\e90a";
                font-family: "icomoon";
                color: $white;
                font-size: 1.6rem;
                opacity: 0;
                transition: opacity .2s ease-out;
            }
        }
    
        &:checked + label {
            &::before {
                background-color: $primary-color;
            }
            &::after {
                opacity: 1;
            }
        }
    }
}

.form-group-line .form-control + .form-control {
    margin-top: 0;
}

.search-control {
    position: relative;
    min-width: 30rem;

    input {
        padding-right: 4.8rem;

        &.input-sm {
            padding-right: 3.2rem;
        }
    }

    .btn-icon {
        @extend %centerY;
        @include size($control-size);
        right: 0;

        .icon::before {
            color: $blue-gray-500;
        }

        &:hover,
        &:focus,
        &:focus-visible {
            .icon::before {
                color: $blue-gray-800;
            }
        }
    }

    .btn-sm {
        @include size($control-size-sm);
    }
}