@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.menu {
    @include size($menu-width, 100vh);
    @include fixed($left: 0, $top: 0);
    padding: 2rem;
    width: $menu-width;
    background-color: $white;
    overflow-y: auto;
    z-index: 10;
}

.menu__section + .menu__section {
    margin-top: 2rem;
}
.menu__section-title {
    color: $blue-gray-800;
    font-size: 2rem;
    font-weight: 600;
    line-height: 100%;

}
.menu__list {
    margin-top: 1.6rem;
}
.menu__link {
    @extend %flex-center-y;
    position: relative;
    column-gap: .8rem;
    padding: 1rem;
    border-radius: $border-radius;
    transition: background-color .3s ease-out;

    .icon {
        font-size: 2.4rem;

        &::before {
            color: $blue-gray-600;
        }
    }

    &:hover {
        background-color: $blue-50;
    }

    &._active {
        background-color: $blue-100;
    }
}

.menu__link-name {
    font-size: 1.6rem;
    line-height: 100%;
}
.menu__link-count {
    @extend %centerY;
    right: 1rem;
    display: inline-block;
    padding: .3rem .8rem;
    background-color: $primary-color;
    border-radius: $border-radius;
    color: $white;
    font-size: 1.2rem;
    line-height: 100%;

    &._bg-green {
        background-color: $green-400;
    }

    &._bg-pink {
        background-color: $pink-400;
    }

    &._bg-red {
        background-color: $red-400;
    }
}
