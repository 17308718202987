@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.project-schedule {
    display: flex;
    column-gap: 1.2rem;
    margin-top: 2.4rem;
    padding-right: 3.2rem;
}

.project-schedule__item {
    @extend %flex-center-y;
    flex-basis: 100%;
    position: relative;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    height: 8rem;
    border: .2rem solid $white;
    border-radius: .8rem;
    color: $white;
    font-size: 2.4rem;
    line-height: 120%;
}

.project-schedule__data {
    @include absolute($top: -3.2rem, $right: 0);
    font-size: 2rem;
    font-weight: 600;
    line-height: 100%;
    transform: translateX(calc(50% + .8rem));
}