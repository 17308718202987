* {
    box-sizing: border-box;
    color: $main-text-color;
    font-family: $font-family;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.2;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    position: relative;
    background-color: $main-bg-color;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-track {
    padding-top: 1.6rem;
    background-color: $scrollbar-track-bg-color;
}

::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-bg-color;
    border-radius: $border-radius;
    box-shadow: inset 0 0 2px rgba($scrollbar-thumb-shadow-color, 0.2);
}

::selection {
    background: $main-selection-color;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

main {
    padding: calc(3.2rem + $header-height) 3.2rem 3.2rem calc(3.2rem + $menu-width);

    > * + * {
        margin-top: 3.2rem;
    }
}

a {
    text-decoration: none;

    &:focus {
        outline: none;
    }
}

img {
    max-width: 100%;
}

$colors: (
    "gray": $blue-gray-500,
    "green": $green-800,
    "red": $red-700,
    "blue": $blue-500,
    "violet": $violet-700,
    "orange": $orange-300
);

@each $name, $variable in $colors {
    ._color-#{$name} {
        color: $variable;
    }

    ._bg-#{$name} {
        background-color: $variable;
    }
}

.d-flex {
    display: flex;
}

.spaces-y-8 > * + * {
    margin-top: .8rem;
}

.spaces-x-80 > * + * {
    margin-left: 8rem;
}

.mt-24 {
    margin-top: 2.4rem;
}

.main-title {
    font-size: 3.6rem;
    font-weight: 600;
    list-style: 122%;
}

.section-title {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 129%;
}