@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.tasks-menu {
    flex: 0 0 21.4rem;
    min-height: 100%;
    padding: 2rem;
    background-color: $blue-gray-50;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.tasks-menu__title {
    color: $blue-gray-800;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 100%;
}

.tasks-menu__list {
    margin-top: 1.6rem;
}

.tasks-menu__item input {
    display: none;

    &:checked + .tasks-menu__item-label {
        background-color: $blue-100;
    }
}

.tasks-menu__item-label {
    @extend %flex-center-y;
    position: relative;
    column-gap: .8rem;
    padding: 1rem;
    border-radius: $border-radius;
    color: $secondary-text-color;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 100%;
    transition: background-color .3s ease-out;

    .icon {
        font-size: 2rem;

        &::before {
            color: $blue-gray-600;
        }
    }

    &:hover {
        background-color: $blue-50;
    }
}

.tasks-menu__item-count {
    @extend %centerY;
    right: 1rem;
    font-size: 1.4rem;
}