@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.task-add-form {
    flex: 0 0 36rem;
    min-height: 100%;
    padding: 2rem;
    background-color: $blue-gray-50;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.task-add-form__title {
    color: $blue-gray-800;
    font-size: 2rem;
    font-weight: 600;
    line-height: 100%;
}

.task-add-form__form {
    margin-top: 2.4rem;
    width: 100%;

    .form-control {
        width: 100%;
    }
}