@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.team-item {
    min-width: 30rem;
    padding: 2rem;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.team-item__main-part {
    display: flex;
    justify-content: space-between;

    .btn {
        margin-right: -1rem;
        margin-top: -.8rem
    }
}

.team-item__info {
    @extend %flex-center-y;
    column-gap: 1.6rem;


}

.team-item__name {
    display: block;
    color: $secondary-text-color;
    font-size: 1.6rem;
    line-height: 150%;
}

.team-item__job-position {
    display: inline-block;
    margin-top: .8rem;
    padding: .2rem .8rem;
    background-color: $blue-gray-100;
    border-radius: 1rem;
    color: $blue-600;
    font-size: 1.4rem;
    line-height: 125%;
}

.team-item__btns {
    margin-top: 2rem;

    .btn + .btn {
        margin-left: 1.2rem;
    }
} 