@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.infographics-item {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    justify-content: space-between;
    padding: 2rem;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.infographics-item__data {
    margin: 0;
    grid-row: 1 / 3;
}

.infographics-item__heading {
    font-weight: 600;
    font-size: 2rem;
    line-height: 140%;
    color: $blue-gray-600;
}

.infographics-item__sum-total {
    margin: 1.2rem 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;

    span {
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 122%;
        color: $blue-gray-800;
        white-space: nowrap;
    }
}

.infographics-item__link {
    grid-column: 1 / 2;
    align-self: end;
    margin-right: auto;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 114%;
    color: $blue-700;
    border-bottom: 0.1rem solid $blue-700;
}

.infographics-item__link::before {
    @include absolute($left: 0, $top: 0);
    @include size(100%);
    content: "";
}

.infographics-item__trend {
    @extend %flex-center-y;
    grid-column: -1 / -2;
    grid-row: 1 / 2;
    gap: 1rem;
    width: 8.9rem;

    span {
        color: inherit;
    }
}

.infographics-item__trend-icon::before {
    color: inherit;
}

.infographics-item__trend-percent {
    font-size: 1.4rem;
    line-height: 143%;
}

.infographics-item__trend_up {
    color: $green-500;
}

.infographics-item__trend_down {
    color: $red-400;
}

.infographics-item__currency {
    @extend %flex-center;
    @include size(6.2rem);
    grid-column: -1 / -2;
    grid-row: -1 / -3;
    justify-self: end;
    align-self: end;
    border-radius: $border-radius;
    background-color: $green-200;
}

.infographics-item__currency::before {
    @extend %flex-center;
    @include size(5.2rem);
    font-size: 3rem;
    color: $white;
    border: 0.2rem solid $white;
    border-radius: $border-radius;
    background-color: $green-400;
}