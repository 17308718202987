@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.tasks-list {
    flex-grow: 1;
    background-color: $blue-gray-50;
    border-left: thin solid $blue-50;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.tasks-list__filters {
    @extend %flex-center-y;
    justify-content: space-between;
    padding: 1rem 2.4rem 1rem 3.5rem;
    background-color: $white;
    border-bottom: thin solid $blue-50;
}

.tasks-list__filters-wrap {
    @extend %flex-center-y;
    column-gap: 1.8rem;
}

.tasks-list__filters-checkbox {
    @extend %flex-center-y;
    line-height: 2.2rem;

    input {
        display: none;

        &:checked + label {
            &::before {
                background-color: $blue-500;
                border-color: $blue-500;
            }

            &::after {
                color: $white;
            }
        }
    }

    label {
        position: relative;
        padding-left: 2.2rem;
        font-size: 0;
        cursor: pointer;
        line-height: 2.2rem;

        &::before {
            @extend %center;
            @include size(2rem);
            content: "";
            display: inline-block;
            border: thin solid $blue-gray-600;
            border-radius: 50%;
            transition: background-color .2s ease-out, border-color .2s ease-out;
        }

        &::after {
            @extend %center;
            content: "\e90a";
            color: $blue-gray-600;
            font-family: "icomoon";
            font-size: 1.4rem;
            transition: color .2s ease-out;
        }

        &:hover {
            &::before {
                border-color: $blue-800;
            }

            &::after {
                color: $blue-800;
            }
        }
    }
}

.tasks-list__filters-radio-list {
    display: inline-flex;
    align-items: center;
    column-gap: 1.4rem;
}

.tasks-list__filters-radio {
    input {
        display: none;

        &:checked + label::before {
            background-color: $blue-500;
        }
    }

    label {
        position: relative;
        font-size: 1.8rem;
        line-height: 133%;
        cursor: pointer;
        transition: color .2s ease-out;

        &::before {
            @extend %centerX;
            @include size(calc(100% - .8rem), .2rem);
            content: "";
            display: block;
            bottom: -.2rem;
            transition: background-color .2s ease-out;
        }

        &:hover,
        &:focus {
            color: $blue-800;
        }
    }
}

.tasks-list__header {
    display: grid;
    grid-template-columns: 2.8rem 16% 13% auto repeat(4, 14rem);
    align-items: center;
    padding: .6rem .6rem .6rem 3.2rem;
    background-color: $white;

    &._completed {
        grid-template-columns: 2.8rem 19% 15% auto 14rem 16rem;
    }

    &._new {
        grid-template-columns: 2.8rem 18% 15% auto 14rem;
    }
}

.tasks-list__header-item {
    padding: .1rem 1.6rem;
    font-size: 1.4rem;

    &:nth-child(n+3) {
        border-left: thin solid $blue-100;
    }
}

.tasks-list__day {
    padding: .8rem .6rem .6rem 7.6rem;
    border-bottom: thin solid $blue-50;
    color: $secondary-text-color;
    font-size: 1.4rem;
    font-weight: 600;
}
