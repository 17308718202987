@font-face {
    font-family: 'icomoon';
		src: url('../resources/iconfont/icomoon.woff') format('woff'),
		url('../resources/iconfont/icomoon.eot') format('embedded-opentype'),
		url('../resources/iconfont/icomoon.ttf') format('truetype'),
		url('../resources/iconfont/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

%ic {
	font-family: 'icomoon' !important;
    speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon {
    @extend %ic;
    color: $main-text-color;
}

.icon-archive:before {
    content: "\e900";
}
.icon-arrow-circle-right:before {
    content: "\e901";
}
.icon-arrow-reply-all:before {
    content: "\e902";
}
.icon-arrow-undo:before {
    content: "\e903";
}
.icon-border-style:before {
    content: "\e904";
}
.icon-broom:before {
    content: "\e905";
}
.icon-calendar-select-day:before {
    content: "\e906";
}
.icon-chart-pie:before {
    content: "\e907";
}
.icon-chart-trending-up:before {
    content: "\e908";
}
.icon-chat:before {
    content: "\e909";
}
.icon-check:before {
    content: "\e90a";
}
.icon-chevron-down:before {
    content: "\e90b";
}
.icon-clock:before {
    content: "\e90c";
}
.icon-delete:before {
    content: "\e90d";
}
.icon-dollar:before {
    content: "\e90e";
}
.icon-draft:before {
    content: "\e90f";
}
.icon-email:before {
    content: "\e910";
}
.icon-file-add:before {
    content: "\e911";
}
.icon-filter:before {
    content: "\e912";
}
.icon-flag:before {
    content: "\e913";
}
.icon-folder-arrow-right:before {
    content: "\e914";
}
.icon-heart:before {
    content: "\e915";
}
.icon-inbox:before {
    content: "\e916";
}
.icon-mail-read-multiple:before {
    content: "\e917";
}
.icon-map:before {
    content: "\e918";
}
.icon-menu:before {
    content: "\e919";
}
.icon-more:before {
    content: "\e91a";
}
.icon-more-ver:before {
    content: "\e91b";
}
.icon-notification:before {
    content: "\e91c";
}
.icon-phone:before {
    content: "\e91d";
}
.icon-pin:before {
    content: "\e91e";
}
.icon-search:before {
    content: "\e91f";
}
.icon-send:before {
    content: "\e920";
}
.icon-settings:before {
    content: "\e921";
}
.icon-shield-error:before {
    content: "\e922";
}
.icon-suitcase:before {
    content: "\e923";
}
.icon-tag:before {
    content: "\e924";
}
.icon-chart-trending-down:before {
    content: "\e925";
}
.icon-trophy:before {
    content: "\e926";
}
.icon-view-boxes:before {
    content: "\e927";
}
.icon-view-table:before {
    content: "\e928";
}
.icon-view-week:before {
    content: "\e929";
}