.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.title {
    font-size: 2em;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.label {
    margin-bottom: 5px;
}

.input {
    margin-bottom: 15px;
    padding: 8px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button {
    padding: 10px;
    font-size: 1em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.button:hover {
    background-color: #0056b3;
}

.footer {
    margin-top: 20px;
    text-align: center;
}

.linkButton {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    font-size: 1em;
}

.linkButton:hover {
    color: #0056b3;
}

.error {
    color: red;
    margin-top: 10px;
}