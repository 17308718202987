@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/extends.scss';

.tasks-actions {
    @extend %flex-center-y;
    column-gap: .4rem;
    border-radius: $border-radius;
    padding: .4rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    overflow-x: auto;
}

.tasks-actions__center-part {
    @extend %flex-center-y;
    margin-left: 1%;
}

.tasks-actions__right-part {
    @extend %flex-center-y;

    .btn {
        padding-left: .8rem;
        padding-right: .8rem;

        .icon._left {
            margin-right: 0;
        }
    }
}

.tasks-actions__delimiter {
    @include size(.1rem, 3.2rem);
    background-color: $blue-gray-200;
}
