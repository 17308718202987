@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $control-size;
    padding: 0 2rem;
    border: .2rem solid transparent;  
    border-radius: $border-radius;
    background-color: transparent; 
    cursor: pointer;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1;
    white-space: nowrap;
    transition: color .3s ease-out, background-color .3s ease-out, border-color .3s ease-out;

    &:disabled {
        opacity: .5;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $blue-50;
        outline: none;
    }

    .icon {
        margin-top: -.2rem;
        font-size: 2.4rem;
        transition: color .3s ease-out;

        &._left {
            margin-right: .8rem;
        }

        &._right {
            margin-left: .8rem;
        }

        &.icon-chevron-down,
        &.icon-chevron-up {
            font-size: 1.2rem;
        }
    }
}

//Button types

.btn-primary {
    @include button-style(
        $color: $white,
        $background-color: $primary-color,
        $background-color-hover: darken($primary-color, 5%)
    )
}

.btn-primary-outline {
    @include button-style(
        $color: $blue-gray-800,
        $color-hover: $white,
        $background-color: $white,
        $background-color-hover: $primary-color,
        $border-color: $primary-color
    ) 
}

.btn-secondary {
    @include button-style(
        $color: $white,
        $background-color: $green-500,
        $background-color-hover: darken($green-500, 5%)
    )
}

.btn-icon-circle {
    @include button-style(
        $color: $primary-color,
        $color-hover: $white,
        $background-color: $white,
        $background-color-hover: $primary-color,
        $border-color: $primary-color
    ); 
    width: $control-size;
    font-size: 0;
    border-radius: 50%;

    &.btn-sm {
        font-size: 0;
        width: $control-size-sm;
    }

    .icon._left,
    .icon._rigth,
    &.btn-sm .icon._left,
    &.btn-sm .icon._rigth,
    &.btn-xs .icon._left,
    &.btn-xs .icon._rigth {
        margin: 0;
    }
}

.btn-icon {
    @include button-style(
        $color-hover: $blue-800,
        $background-color: transparent
    ); 
    @include size(4rem);
    font-size: 0;
    border-radius: $border-radius;

    .icon._left,
    .icon._rigth,
    &.btn-sm .icon._left,
    &.btn-sm .icon._rigth,
    &.btn-xs .icon._left,
    &.btn-xs .icon._rigth {
        margin: 0;
    }

    &.btn-sm,
    &.btn-xs {
        font-size: 0;
    }

    &._with-indicator::before {
        @include absolute($right: .4rem, $top: -.4rem);
        @include size(.6rem);
        content: "";
        display: block;
        background-color: $green-500;
        border: .3rem solid $green-200;
        border-radius: 50%;
    }

    &.btn-sm._with-indicator::before {
        top: 0;
    }

    &._two-icons {
        width: auto;
    }
}

.btn-sm,
.btn-xs {
    padding: 0 1rem;
    font-size: 1.4rem;

    .icon {
        font-size: 2rem;

        &._left {
            margin-right: .6rem;
        }

        &._right {
            margin-left: .6rem;
        }
    }
}

.btn-sm {
    height: $control-size-sm;
}

.btn-xs {
    height: $control-size-xs;
}

.btn-text {
    @include button-style(
        $color-hover: $blue-800,
        $background-color-hover: transparent
    ); 
    padding: 0;
    height: auto;
}